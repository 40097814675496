import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//成交分析区域 数据
export function zhibolist(params) {
	return loginRequest({
    url: '/frontend/website/zhibo/list',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//列表数据
export function list(params) {
	return loginRequest({
    url: '/frontend/website/zhibo/page-list',
		method: 'post',
		data:Qs.stringify(params)
	})
}


